import React, { useEffect, useState } from "react";
import cx from "classnames";
import { kebabCase } from "utils/kebabCase";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styles from "./TableOfContents.module.scss";
import { useScroll } from "components/hooks/useScroll";
import FancyClassIcon from "components/ui/FancyClassIcon/FancyClassIcon"

type THeading = { value: string; depth: number };

interface Props {
  title: string;
  headings: THeading[];
  slug: string;
  icon: string;
}

export const TableOfContents = ({ title, headings, slug, icon }: Props) => {
  const [headingPos, setHeadingPos] = useState<number[]>([]);
  const toc = headings.filter(h => h.depth <= 2);
  useEffect(() => {
    setHeadingPos(
      toc.map(h => {
        const id = kebabCase(h.value, true);
        const ele = document.getElementById(id);
        return (ele && ele.offsetTop) || 0;
      })
    );
  }, []);
  const { top } = useScroll();
  const closestHeadingToTop = headingPos.indexOf(
    headingPos.reduce((prev, curr) => {
      return Math.abs(curr - top) < Math.abs(prev - top) ? curr : prev;
    }, 0)
  );
  return (
    <div className={styles.root}>
      <div className={styles.sticky}>
        <FancyClassIcon characterClass={icon} superFancy />
        <AnchorLink to={`${slug}#x5c5ba9ec-786b-11ea-bc55`}>
          <div className={styles.title}>{title}</div>
        </AnchorLink>

        <div className={styles.headingsContainer}>
          {toc.map((h, i) => (
            <TocHeading
              key={i}
              heading={h}
              slug={slug}
              active={i == closestHeadingToTop}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

interface HeadingProps {
  heading: THeading;
  slug: string;
  active: boolean;
}

const TocHeading = ({ heading, slug, active }: HeadingProps) => (
  <AnchorLink to={`${slug}#${kebabCase(heading.value, true)}`}>
    <div
      className={cx(
        styles.heading,
        heading.depth === 1 && styles.mainHeading,
        active && styles.active
      )}
    >
      {heading.value}
    </div>
  </AnchorLink>
);
