import React, { useEffect, useRef, useState } from "react"

const defaultY = typeof window !== 'undefined' ? window.pageYOffset :0;
const defaultX = typeof window !== 'undefined' ? window.pageXOffset :0;

function useScroll() {
  const [scroll, setScroll] = useState({
    top: defaultY,
    left: defaultX,
  });
  const topRef = useRef(0);
  const leftRef = useRef(0);
  const onScroll = throttle(() => {
    if (
      window.pageYOffset !== topRef.current ||
      window.pageXOffset !== leftRef.current
    ) {
      topRef.current = window.pageYOffset;
      leftRef.current = window.pageXOffset;

      setScroll({
        top: window.pageYOffset,
        left: window.pageXOffset,
      });
    }
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return scroll;
}

export { useScroll };

const throttle = (fn: () => any, wait: number) => {
  let time = Date.now();
  return function throttle() {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
};
