import React from "react";
import { graphql, Link } from "gatsby";
import styles from "./MarkdownTemplate.module.scss";
import SEO from "components/seo";
import { TableOfContents } from "components/ui/TableOfContents/TableOfContents";
import AppContentContainer from "components/layouts/appLayout/AppContentContainer";
import { EldritchRune } from "components/ui/icons/MagicCircles";

interface TemplateProps {
  data: any;
}

const Template = ({
  data, // this prop will be injected by the GraphQL query below.
}: TemplateProps) => {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const {
    frontmatter,
    html,
    headings,
    fields: { slug },
  } = markdownRemark;
  const icon = `${frontmatter.parent || frontmatter.title}`.replace(" ","");
  return (
    <AppContentContainer spacing="large">
      <SEO title={frontmatter.title} />
      <div className={styles.root}>
        <div className={styles.foreground}>
          <TableOfContents
            title={frontmatter.title}
            headings={headings}
            slug={slug}
            icon={icon}
          />

          <div className={styles.contentContainer}>
            <div
              className={styles.titleContainer}
              id="x5c5ba9ec-786b-11ea-bc55"
            >
              {frontmatter.parent && (
                <Link to={`/homebrew/classes/${frontmatter.parent.toLowerCase().replace(" ","")}`}>
                  <h2 className={styles.parentLink}>{frontmatter.parent}</h2>
                </Link>
              )}
              <h1>{frontmatter.title}</h1>
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
        <div className={styles.runeContainer}>
          <EldritchRune className={styles.eldritchRune} />
        </div>
      </div>
    </AppContentContainer>
  );
};
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        parent
      }
      headings {
        depth
        value
      }
      fields {
        slug
      }
    }
  }
`;
export default Template;
